<template>
  <v-snackbar
    v-model="isDisplay"
    :top="position.top"
    :left="position.left"
    :right="position.right"
    :bottom="position.bottom"
    :color="color"
    :rounded="style.rounded"
    :timeout="timeout"
  >
    <v-container class="my-0 py-0">
      <v-row
        align="center"
      >
        <v-col class="pa-1">
          <v-icon
            v-if="type === 'success'"
            small
            class="mr-2"
          >
            {{ icons.success }}
          </v-icon>
          <v-icon
            v-if="type === 'info'"
            small
            class="mr-2"
          >
            {{ icons.info }}
          </v-icon>
          <v-icon
            v-if="type === 'warning'"
            small
            class="mr-2"
          >
            {{ icons.warning }}
          </v-icon>
          <v-icon
            v-if="type === 'error'"
            small
            class="mr-2"
          >
            {{ icons.error }}
          </v-icon>
          {{ text }}
        </v-col>
        <v-col
          cols="auto"
          class="pa-1 text-right"
          style="margin-right:-14px;"
        >
          <v-btn
            small
            icon
            @click="isDisplay = false"
          >
            <!-- Close -->
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      position: {
        top: true,
        left: false,
        right: true,
        bottom: false
      },
      style: {
        rounded: ''
      },
      icons: {
        success: 'mdi-check',
        info: 'mdi-alert-circle-outline',
        warning: 'mdi-alert',
        error: 'mdi-alert'
      },
      type: '',
      text: '',
      color: '',
      timeout: 2000,
      isDisplay: false
    }
  },
  created() {
    if (this.$vuetify.icons.iconfont === 'mdi') {
      this.icons.success = 'mdi-check'
      this.icons.info = 'mdi-alert-circle-outline'
      this.icons.warning = 'mdi-alert'
      this.icons.error = 'mdi-alert'
    } else if (this.$vuetify.icons.iconfont === 'google-material') {
      this.icons.success = 'check'
      this.icons.info = 'info'
      this.icons.warning = 'priority_high'
      this.icons.error = 'error'
    }
    const self = this
    this.$bus.$on('successMessage', (msg) => {
      self.showSnackbar(msg, 'primary', 'success')
    })
    this.$bus.$on('infoMessage', (msg) => {
      self.showSnackbar(msg, 'info', 'info')
    })
    this.$bus.$on('warningMessage', (msg) => {
      self.showSnackbar(msg, 'warning', 'warning')
    })
    this.$bus.$on('errorMessage', (msg) => {
      self.showSnackbar(msg, 'error', 'error')
    })
    this.$bus.$on('defaultMessage', (msg) => {
      self.showSnackbar(msg, '', '')
    })
    this.$bus.$on('showMessage', (msg, status) => {
      self.isDisplay = true
      self.text = msg
      if (status === 'success') {
        self.color = 'success'
      } else if (status === 'info') {
        self.color = 'info'
      } else if (status === 'error') {
        self.color = 'error'
      } else {
        self.color = ''
      }
    })
  },
  methods: {
    showSnackbar(msg, color, type) {
      this.isDisplay = true
      this.text = msg
      this.color = color
      this.type = type
    }
  }
}
</script>

<style scoped></style>
